import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import TagList from "../components/tag-list"

function BlogSummary({ node }) {
  const title = node.frontmatter.title || node.fields.slug
  return (
    <div key={node.fields.slug}>
      <h3
        style={{
          marginBottom: rhythm(1 / 4),
        }}
      >
        <Link style={{ boxShadow: `none` }} to={`blog${node.fields.slug}`}>
          {title}
        </Link>
      </h3>
      <small>{}</small>
      <p
        style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
          marginTop: rhythm(-0.2),
        }}
      >
        {node.frontmatter.date}
      </p>
      <TagList tags={node.frontmatter.tags} />
      <p
        dangerouslySetInnerHTML={{
          __html: node.frontmatter.description || node.excerpt,
        }}
      />
    </div>
  )
}

export default BlogSummary
