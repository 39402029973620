import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import BlogSummary from "../components/blog-summary"
import { rhythm } from "../utils/typography"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props

    const siteTitle = "Hiep Doan"
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `Hiep Doan`, `Software Engineer`, `react`]}
        />
        <h4>
          <span role="img" aria-label="wave emoji">
            👋👋
          </span>
        </h4>
        <p>
          Hey, thanks for being here. <br />
          <br /> I am a software developer currently based in a beautiful city
          of Zürich, Switzerland. I am passionate about designing and developing
          a tech product which can help to solve a meaningful problem (and
          hopfully not cause more problems along the way).
          <br /> <br />I am a strong believer in the value of lifelong learning
          and sharing is the one of the best ways to learning. Thus, this
          {` `}
          <Link to="/blog/">blog</Link>
          {` `}
          I devoted to share random observations, thoughts and experiments in
          both my professional and personal life.
          <br /> <br />
          Other places where I am also present:
          <br />
          <a href={`https://medium.com/@alexthered`} target="_blank">
            Medium
          </a>
          <br />
          <a href={`https://twitter.com/AlexTheRed179`} target="_blank">
            Twitter
          </a>
          <br />
          <a href={`https://github.com/alexthered`} target="_blank">
            Github
          </a>
        </p>

        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />

        <h4
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          Latest articles:
        </h4>

        <div style={{ margin: "20px 0 40px" }}>
          {posts.map(({ node }) => (
            <BlogSummary node={node} />
          ))}
        </div>
        <Link to="/blog">
          <Button marginTop="85px">Visit my blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
